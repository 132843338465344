import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import LayoutFrame from '../components/layout/LayoutFrame';
import { Typography } from '@nelnet/unifi-components-react';

const Error: React.FunctionComponent = () => {
  return (
    //TODO: figure out how to incorporate this page in so it's actually being called/used
    <LayoutFrame currPath="">
      <Helmet>
        <title>404 Error</title>
      </Helmet>
      <Typography variant="h2" margin={{ bottom: 3 }}>
        Oops, that page could not be found.
      </Typography>
    </LayoutFrame>
  );
};

export default Error;
